@import url("https://fonts.googleapis.com/css2?family=Staatliches&display=swap");

body {
  background-color: #121213;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
}

#main {
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: 50;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  height: 100vh;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

h1 {
  font-family: 'Staatliches';
  font-size: 35px;
  color: #d7dadc;
  border-bottom: thin solid #3a3a3c;
  width: 120vw;
  text-align: center;
  margin-top: 10px;
  padding-bottom: 10px;
}

#board {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

[id^="row-"] {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2.5px;
}

input { 
  width: 35px;
  height: 35px;
  text-align: center;
  background-color: #121213;
  margin-right: 2.5px;
  margin-left: 2.5px;
  border: 2.5px solid #3a3a3c;
  margin-bottom: 2.5px;
  cursor: default;
  font-family: 'Staatliches';
  font-size: 20px;
  float: left;
  color: transparent;
  text-shadow: 0 0 0 #d7dadc;
  z-index: -1;
}

input:focus {
  outline:none;    
}

[id^="key-"] {
  background-color: #232324;
  margin: 2.5px;
  padding: 15px;
  color: rgba(255,255,255,.5);
  cursor: pointer;
  font-family: 'Staatliches';
  border-radius: 10%;
}

.keyboard-row {
  display: flex;
  flex-direction: row;
  padding: 2.5px;
  justify-content: center;
}

#keyboard {
  margin-bottom: 5%;
  z-index: 999;
  min-width: 90vw;
}

@media screen and (max-width: 600px) {
  [id^="key-"] {
    padding: 10px !important;
  }
}

#play-again {
  visibility: hidden;
  cursor: pointer;
  border: none;
  background-color: #232324;
  margin: 2.5px;
  padding: 10px;
  color: rgba(255,255,255,.5);
  cursor: pointer;
  font-family: 'Staatliches';
  border-radius: 5%;
  font-size: 21px;
}
#play-again-icon {
  margin-bottom: -3px;
}